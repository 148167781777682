import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import * as styles from './styles.module.scss';

export default function ContactForm() {

  function sendForm(e) {
    e.preventDefault();
    if (window && window.grecaptcha) {
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute('6LfzUs4bAAAAAKb_HJJk6PkvoMTwgrwFDplLjzqA', {action: 'homepage'})
          .then(function(token) {
            document.getElementById('captchaResponse').value = token;
            const name = document.getElementById('name').value;
            const message = document.getElementById('message').value;
            if (name && message) {
              document.getElementById('contactForm').submit();
            }
          });
      });
    }
  }

  const addScript = (url, callback) => {
    const script = document.createElement('script');
    script.src = url;
    script.async=true;
    script.onload = callback;
    document.head.appendChild(script);
 };

  useEffect(()=>{
    addScript('https://www.google.com/recaptcha/api.js?render=6LfzUs4bAAAAAKb_HJJk6PkvoMTwgrwFDplLjzqA' /*, validateRecaptcha()*/)
  },[]);

  return (
    <div className={styles.container} >
      <h3>Contacta conmigo</h3>
      <div className={styles.content} >
        <form
          acceptCharset="UTF-8"
          action="https://getform.io/f/d13a5f31-6ef9-4a4b-84db-574d26a7eb65"
          method="POST"
          encType="multipart/form-data"
          id="contactForm"
        >
          <input type="hidden" id="captchaResponse" name="g-recaptcha-response"></input>
          <div className={styles.inputContainer}>
            <FontAwesomeIcon height="1em" style={{ paddingLeft: '0.5em' }} icon={faUser} />
            <input
              type="text"
              name="name"
              className={styles.formControl}
              id="name"
              placeholder="Introduce tu nombre"
            />
          </div>
          <div className={styles.inputContainer}>
            <FontAwesomeIcon height="1em" style={{ paddingLeft: '0.5em' }} icon={faEnvelope} />
            <input
              type="email"
              name="email"
              className={styles.formControl}
              id="email"
              placeholder="Introduce tu email"
              required="required"
            />
          </div>
          <div className={styles.inputContainer}>
            <textarea
              name="message"
              id="message"
              className={styles.formControl}
              placeholder="Deja tu mensaje aquí"
              rows="6"
            />
          </div>
          <hr />
          <button
            className="btn btn-primary"
            type="submit"
            onClick={e => sendForm(e)}
          >
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
}
